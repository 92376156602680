/**
 * font definitions
 */

@font-face {
    font-family: 'Francois One';
    font-style: normal;
    font-weight: 400;
    src: local('Francois One Stripped'), local('FrancoisOne-Stripped'), url(/fonts/francoisone/FrancoisOne-Stripped.woff) format('truetype');
}
